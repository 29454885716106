<script setup lang="ts">
import { LogoLink } from '~/components'
import { useHead } from '#imports'

useHead({
  htmlAttrs: {
    class: 'def'
  }
})
</script>

<template>
  <section class="container">
    <header>
      <LogoLink />
    </header>
    <main>
      <slot />
    </main>
  </section>
</template>
